import {Link} from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import Favicon from './favicon';
import styles from './header.module.css';

const headerId = 'mobile-header';

class Header extends React.Component {
  constructor() {
    super();

    this.state = {
      menuActive: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleGlobalClick = this.handleGlobalClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener('click', this.handleGlobalClick);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleGlobalClick);
  }

  handleGlobalClick(e) {
    const headerClick = document.querySelector(`#${headerId}`).contains(e.target);
    if (this.state.menuActive && !headerClick ) {
      this.toggleMenu();
    }
  }

  toggleMenu() {
    this.setState((previousState) => ({
      ...previousState,
      menuActive: !previousState.menuActive,
    }));
  }

  render() {
    const {siteTitle} = this.props;
    const {menuActive} = this.state;
    const headerClasses = classNames({
      [styles.header]: true,
      [styles.menuActive]: menuActive,
    });
    const hamburgerClasses = classNames({
      [styles.hamburger]: true,
      [styles.active]: menuActive,
    });

    const mobileMenuClasses = classNames({
      [styles.mobileMenu]: true,
      [styles.menuActive]: menuActive,
    });

    return (
      <header id={headerId} className={headerClasses}>
        <Link to="/" className={`${styles.headerLink} ${styles.homeLink}`}>
          <Favicon className={styles.homeImage} />
          <span>{siteTitle}</span>
        </Link>
        <div className={styles.linkWrapper}>
          <Link to="/now" className={`${styles.headerLink} ${styles.pageLink}`}>Now</Link>
          <Link to="/projects" className={`${styles.headerLink} ${styles.pageLink}`}>Projects</Link>
          <Link to="/travel" className={`${styles.headerLink} ${styles.pageLink}`}>Travel</Link>
          <Link to="/blog" className={`${styles.headerLink} ${styles.pageLink}`}>Blog</Link>
        </div>
        <button className={hamburgerClasses} type="button" onClick={this.toggleMenu}>
          <span className={styles.hamburgerBox}>
            <span className={styles.hamburgerInner}></span>
          </span>
        </button>
        <div className={mobileMenuClasses}>
          <div><Link to="/now">Now</Link></div>
          <div><Link to="/projects">Projects</Link></div>
          <div><Link to="/travel">Travel</Link></div>
          <div><Link to="/blog">Blog</Link></div>
        </div>
      </header>
    );
  }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
